<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">{{actionType}} Social Media</h4>
      </div>

      <div class="modal-body">
        <div class="form-row">
          <div class="col mb-3">
            <label for="socialMediaType">Social Media Type <span class="red-dot ml-3"></span></label>
            <select id="socialMediaType" name="socialMediaType" class="custom-select" :class="{ 'is-invalid': errors.has('socialMediaType') }"
                    v-validate="'required'" v-model="socialMedia.socialMediaTypeId" data-vv-as="Social Media Type">
              <option v-for="socialMediaType in getSocialMediaTypes" :key="socialMediaType.id" :value="socialMediaType.id">{{ socialMediaType.name | capitalize }}</option>
            </select>
            <div class="invalid-feedback">The Social Media Type field is required</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col mb-3">
            <label for="url">URL <span class="red-dot ml-3"></span></label>
            <input id="url" name="url" type="text" class="form-control" :class="{ 'is-invalid': errors.has('url') }"
                   autocomplete="off" placeholder="URL" v-validate="'required|url'" v-model="socialMedia.companySocialMediaTypeUrl" data-vv-as="URL" />
            <div class="invalid-feedback">The URL field is required and must be a valid URL.</div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import cloneDeep from "lodash/cloneDeep";
  import Toast from "@/utils/toast";
 
  export default {
    name: "company-social-media",
    data: function () {
      return {
        isEdit: false,
        socialMedia: {
          id: null,
          socialMediaTypeId: null,
          socialMediaType: null,
          companySocialMediaTypeURL: null
        }
      };
    },
    computed: {
      ...mapGetters(["getCompanySocialMedia", "getSocialMediaTypes"]),
      actionType: function () {
        return this.isEdit ? 'Edit' : 'Add';
      }
    },
    mounted: function () {
      this.socialMedia = cloneDeep(this.getCompanySocialMedia);
      this.isEdit = !!this.socialMedia.id;
      this.$validator.reset();
    },
    methods: {
      async onSubmit() {
        var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          return;
        }

        this.socialMedia.socialMediaType = this.getSocialMediaTypes.find(mt => mt.id == this.socialMedia.socialMediaTypeId);

        this.saveCompanySocialMedia(this.socialMedia).then(() => {
          let message = this.$createElement('span', {}, ['Successfully updated!', this.$createElement('br'), 'Please remember to save your company.']);
          Toast.success(this, [message]);
          this.$bvModal.hide("bv-modal-company-social-media");
        }).catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
      },
      closeModal() {
        this.$bvModal.hide("bv-modal-company-social-media");
      },
      ...mapActions(["saveCompanySocialMedia"])
    }
  };
</script>
