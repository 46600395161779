<template>
  <fragment>
    <div class="form-group" :id="id">
      <div :class="{'hidden': fileUploaded }">
        <div class="custom-file">
          <input type="file" :id="id" :name="id" class="custom-file-input"
                 :accept="fileAcceptTypes" @change="uploadFile"
                 :data-vv-as="displayName"
                 v-validate="validationRules"
                 :class="{ 'is-invalid': errors.has(id) }"
                 ref="uploadFile">
          <label class="custom-file-label" :for="id">
            <span class="custom-file-label-text" ref="uploadLabel">Choose file...</span>
          </label>
          <small class="form-text text-muted" v-if="useCustomAcceptTypes">Allowed File Types: {{ fileAcceptTypes }} </small>
          <div class="invalid-feedback">{{ errors.first(id) }}</div>
        </div>
      </div>
      <div :class="{'hidden': !fileUploaded }">
        <div class="d-flex flex-row bd-highlight mt-5 mb-3 align-items-center">
          <div class=" mr-5" id="preview" ref="preview"></div>
          <div>
            <a role="button"
               href="#"
               class="d-flex justify-content-between align-items-center flex-column"
               title="Delete"
               @click.prevent="removeFile()">
              <img class="mb-1" src="@/assets/document.svg" alt />
              <span class="small-grey">Remove</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </fragment>
</template>

<style lang="scss">
  .hidden {
    display: none;
  }
</style>

<script>
  export default {
    name: "upload-file",
    //Injecting the parent's validator allows us to place the
    //upload file component into another component and have
    //it be validated at the same time as the parent's fields
    inject: {
      $validator: '$validator'
    },
    data: function () {
      return {
        fileUploaded: false,
        isValid: true,
      };
    },
    props: {
      id: {type: String, require:true},
      displayName: { type: String },
      existingFile: { type: [File, Object], require: true },
      required: {type: Boolean, require: false},
      acceptTypes: { type: Array, require: false },
      uploadMax: { type: Number, require: false },
      type: {
        default: "image",
        validator: function (value) {
          return ["image", "document"].indexOf(value) !== -1;
        }
      }
    },
    mounted: function () {
      this.$nextTick(() => {
        this.loadFile();
      });
    },
    computed: {
      isImageType() {
        return this.type == "image";
      },
      useCustomAcceptTypes() {
        return this.acceptTypes && this.acceptTypes.length > 0;
      },
      fileAcceptTypes() {
        if (this.useCustomAcceptTypes) {
          return this.acceptTypes.map(i => '.' + i).join(", ");
        }
        else {
          return this.isImageType ? "image/*" : "*";
        }
      },
      errorMessage() {
        if (this.useCustomAcceptTypes) {
          return `Sorry, the file must be one of the following types: ${this.fileAcceptTypes}`;
        }
        else {
          return "Sorry, the file must be an image type.";
        }
      },
      validationRules() {
        let rules = [];

        if (this.required) {
          rules.push('required');
        }

        if (this.uploadMax) {
          rules.push('size:' + this.uploadMax);
        }

        if (this.useCustomAcceptTypes) {
          rules.push('ext:' + this.acceptTypes);
        }
        else if (this.isImageType) {
          rules.push('image');
        }

        return rules.join("|");
      }
    },
    methods: {
      async uploadFile(e) {
        //Only validate the upload portion, otherwise
        //will be required to have all parent component fields
        //valid before submitting a file

        var isFormValid = await this.$validator.validate(this.id);

        if (!isFormValid) {
          return;
        }

        this.fileUploaded = true;
        let file = e.target.files[0];

        this.addContentToPreview(this.getUploadedContent(file));

        this.$emit('saveFile', file);
      },
      loadFile() {
        if (this.existingFile) {
          this.fileUploaded = true;
          this.addContentToPreview(this.getExistingContent(this.existingFile));
        }
        else {
          this.fileUploaded = false;
        }
      },
      addContentToPreview(content) {
        if (this.$refs.preview.childNodes.length > 0) {
          this.$refs.preview.replaceChild(content, this.$refs.preview.childNodes[0]);
        }
        else {
          this.$refs.preview.appendChild(content);
        }
      },
      getUploadedContent(file) {
        return this.isImageType ? this.getImageContent(file, true) : this.getDocumentContent(file, true);
      },
      getExistingContent(file) {
        return this.isImageType ? this.getImageContent(file) : this.getDocumentContent(file);
      },
      getImageContent(file, uploaded = false) {
        let img = document.createElement("img");
        img.style.maxWidth = "300px";

        if (uploaded) {
          img.file = file;

          const reader = new FileReader();
          reader.onload = (function (aImg) { return function (e) { aImg.src = e.target.result; }; })(img);
          reader.readAsDataURL(file);
        }
        else {
          img.src = `${process.env.VUE_APP_API_URL}/api/v1/file/${file.id}`;
        }

        return img;
      },
      getDocumentContent(file, uploaded) {
        let link = document.createElement("a");

        link.innerText = uploaded ? file.name : file.fileName;
        link.target = "_blank";
        link.download = link.innerText

        if (uploaded) {
          link.file = file;

          const reader = new FileReader();
          reader.onload = (function (alink) { return function (e) { alink.href = e.target.result; }; })(link);
          reader.readAsDataURL(file);
        }
        else {
          link.href = `${process.env.VUE_APP_API_URL}/api/v1/file/${file.id}`;
        }

        return link;
      },
      removeFile() {
        this.$refs.uploadFile.value = '';
        this.$emit('update:existingFile', null);
        this.fileUploaded = false;
      }
    }
  };
</script>
