<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">{{actionType}} Licence</h4>
      </div>

      <div class="modal-body">
        <div class="form-row">
          <div class="col mb-3">
            <label for="licence">Licence <span class="red-dot ml-3"></span></label>
            <select id="licence" name="licence" class="custom-select" :class="{ 'is-invalid': errors.has('licence') }"
                    v-validate="'required'" v-model="companyLicence.licenceId" data-vv-as="Licence">
              <option v-for="licence in getLicences" :key="licence.id" :value="licence.id">{{ licence.type }}</option>
            </select>
            <div class="invalid-feedback">The Licence field is required</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col mb-3">
            <label for="issuedBy">Issued By <span class="red-dot ml-3"></span></label>
            <input id="issuedBy" name="issuedBy" type="text" class="form-control" :class="{ 'is-invalid': errors.has('issuedBy') }"
                   autocomplete="off" placeholder="Issued By" v-validate="'required'" v-model="companyLicence.issuedBy" data-vv-as="issuedBy" />
            <div class="invalid-feedback">The Issued By field is required.</div>
          </div>
        </div>

        <div class="form-row">
          <div class="col mb-3">
            <label for="expirationDate">Expiry Date <span class="red-dot ml-3"></span></label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text bg-transparent pr-2">
                  <img src="@/assets/cal.svg" alt />
                </span>
              </div>
              <date-picker id="expirationDate" name="expirationDate" class="form-control date-picker-input" :class="{ 'is-invalid': errors.has('expirationDate') }"
                           autocomplete="off" v-model="companyLicence.expirationDate" v-validate="'required'" :config="datepickerOptions" data-vv-as="Expiry Date">
              </date-picker>
              <div class="invalid-feedback">The Expiry Date field is required</div>
            </div>

          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
  import DatePicker from "vue-bootstrap-datetimepicker";
  import { mapGetters, mapActions } from "vuex";
  import cloneDeep from "lodash/cloneDeep";
  import Toast from "@/utils/toast";

  export default {
    name: "company-licence",
    components: { DatePicker },
    data: function () {
      return {
        nowDate: new Date().toISOString().slice(0, 10),
        isEdit: false,
        companyLicence: {
          id: null,
          licenceId: null,
          licence: null,
          expirationDate: null,
          issuedBy: null
        },
        datepickerOptions: null
      };
    },
    computed: {
      ...mapGetters(["getCompanyLicence", "getLicences"]),
      actionType: function () {
        return this.isEdit ? 'Edit' : 'Add';
      }
    },
    created: function () {
      this.datepickerOptions = {
        useCurrent: false,
        format: "L"
      };
    },
    mounted: function () {
      this.companyLicence = cloneDeep(this.getCompanyLicence);

      if (this.companyLicence.expirationDate) {
        this.companyLicence.expirationDate = new Date(this.companyLicence.expirationDate);
      }
      else {
        this.companyLicence.expirationDate = new Date();
      }

      this.isEdit = !!this.companyLicence.id;
      this.$validator.reset();
    },
    methods: {
      async onSubmit() {
        var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          return;
        }

        this.companyLicence.licence = this.getLicences.find(mt => mt.id == this.companyLicence.licenceId);

        this.companyLicence.expirationDate = new Date(this.companyLicence.expirationDate).toISOString();
        
        this.saveCompanyLicence(this.companyLicence).then(() => {
          let message = this.$createElement('span', {}, ['Successfully updated!', this.$createElement('br'), 'Please remember to save your company.']);
          Toast.success(this, [message]);
          this.$bvModal.hide("bv-modal-company-licence");
        }).catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
      },
      closeModal() {
        this.$bvModal.hide("bv-modal-company-licence");
      },
      ...mapActions(["saveCompanyLicence"])
    }
  };
</script>
