<template>
  <fragment>
    <div id="membership-list">
      <h3>Certifications and Bonding</h3>

      <div class="form-group mt-3">
        <label class="d-flex align-items-center font-weight-bold" for="corCertificate">COR Certificate?</label>

        <select id="corCertificate" name="corCertificate" class="custom-select" :class="{ 'is-invalid': errors.has('corCertificate') }"
                v-model="company.corCertificate">
          <option :value="null">Unknown</option>
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>
      </div>

      <div class="form-group mt-3">
        <label class="d-flex align-items-center font-weight-bold" for="Bonded">Bonded?</label>
        <select id="Bonded" name="Bonded" class="custom-select" :class="{ 'is-invalid': errors.has('Bonded') }"
                v-model="company.bonded">
          <option :value="null">Unknown</option>
          <option :value="false">No</option>
          <option :value="true">Yes</option>
        </select>

        <div v-if="company.bonded == true" class="mt-3">
          <label class="d-flex align-items-center font-weight-bold" for="bondDescription">Bond Description</label>
          <textarea id="bondDescription" name="bondDescription" type="text" class="form-control" :class="{ 'is-invalid': errors.has('bondDescription') }"
                    autocomplete="off" placeholder="Bond Description" v-model="company.bondDescription"
                    rows="4" />
        </div>
      </div>

      <div class="form-group mt-3">
        <label class="d-flex align-items-center font-weight-bold" for="wcbCertificate">{{ wsibName }} Certificate</label>
        <UploadFile id="wcbCertificate" type="document" :displayName="wsibName + ' Certificate'" :acceptTypes="['pdf', 'jpg', 'jpeg', 'png']"
          :existingFile.sync="company.wcbCertificate" @saveFile="saveWcbCertificate"></UploadFile>
      </div>

      <div class="form-group mt-3">
        <label class="d-flex align-items-center font-weight-bold" for="liabilityInsurance">Liability Insurance</label>
        <select id="liabilityInsurance" name="liabilityInsurance" class="custom-select"
                v-model="company.liabilityInsuranceId">
          <option :value="null"></option>
          <option v-for="liabilityInsurance in getCompanyLiabilityInsurances" :key="liabilityInsurance.id" :value="liabilityInsurance.id">{{ liabilityInsurance.name }}</option>
        </select>
      </div>
    </div>
  </fragment>
</template>

<script>
  import UploadFile from "@/components/upload-file.vue";
  import { CompanyService } from "@/services/";
  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "company-certifications-bonding",
    props: ["company", "isEdit"],
    components: {
      UploadFile
    },
    computed: {
      ...mapGetters(["getCompanyLiabilityInsurances", "getCompany"]),
      wsibName(){
     return process.env.VUE_APP_TENANT_NAME.toLowerCase() === 'link2build' ? 'WSIB' : 'WCB'
    },
    },
    mounted: function () {
      this.fetchCompanyLiabilityInsurances();
    },
    methods: {
      saveWcbCertificate(uploadedFile) {
        CompanyService.saveCompanyFile(uploadedFile, this.getCompany.id, 'wcb-certificate').then(response => {
          this.company.wcbCertificate = response.data;
        });
      },
      ...mapActions(["fetchCompanyLiabilityInsurances"])
    }
  };
</script>
