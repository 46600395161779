<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">Add Membership</h4>
      </div>

      <div class="modal-body">
        <div class="form-group">
          <label for="zone">Zone <span class="red-dot ml-3"></span></label>
          <select id="zone" name="zone" class="custom-select" :class="{ 'is-invalid': errors.has('zone') }"
              v-model="membership.zoneId" v-validate="'required'" data-vv-as="Zone">
            <option v-for="zone in getZones" :key="zone.id" :value="zone.id">{{ zone.name }}</option>
          </select>
          <div class="invalid-feedback">The Zone field is required</div>
        </div>

        <div class="form-group">
          <label for="role">Membership Level <span class="red-dot ml-3"></span></label>
          <select id="role" name="role" class="custom-select" :class="{ 'is-invalid': errors.has('role') }"
              v-model="membership.roleId" v-validate="'required'" data-vv-as="Membership Level">
            <option v-for="role in availableRoles" :key="role.id" :value="role.id">{{ role.name }}</option>
          </select>
          <div class="invalid-feedback">The Membership Level field is required</div>
        </div>

        <div class="form-group">
          <label for="expiryDate">Expiry Date <span class="red-dot ml-3"></span></label>
          <div class="input-group mb-2">
            <div class="input-group-prepend">
              <span class="input-group-text bg-transparent pr-2">
                <img src="@/assets/cal.svg" alt />
              </span>
            </div>
            <date-picker id="expiryDate" name="expiryDate" class="form-control date-picker-input" :class="{ 'is-invalid': errors.has('expiryDate') }"
              autocomplete="off" v-model="membership.expiryDate" v-validate="'required'" :config="datepickerOptions" data-vv-as="Expiry Date">
            </date-picker>
            <div class="invalid-feedback">The Expiry Date field is required</div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import DatePicker from "vue-bootstrap-datetimepicker";
import { mapGetters, mapActions } from "vuex";
import Toast from "@/utils/toast";

export default {
  name: "company-membership",
  components: { DatePicker },
  data: function() {
    return {
      membership: {
        id: null,
        zoneId: null,
        roleId: null,
        expiryDate: null
      },
      datepickerOptions: null
    };
  },
  computed: {
    ...mapGetters(["getCompanyMembership", "getRoles", "getZones"]),
    availableRoles() {
      return this.getRoles.filter(role => role.isMembershipType);
    }
  },
  created: function() {
    this.datepickerOptions = {
      useCurrent: false,
      format: "L"
    };
  },
  mounted: function() {
    this.membership = Object.assign({}, this.getCompanyMembership);
    if (this.membership.expiryDate) {
      this.membership.expiryDate = new Date(this.membership.expiryDate);
    }
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }

      // set to date object to allow display filter understand it
      this.membership.expiryDate = new Date(this.membership.expiryDate).toISOString();
      this.membership.role = this.availableRoles.find(i => i.id === this.membership.roleId).name;
      this.membership.zone = this.getZones.find(i => i.id === this.membership.zoneId).name;

      this.saveCompanyMembership(this.membership).then(() => {
        let message = this.$createElement('span', {}, [ 'Successfully updated!', this.$createElement('br'), 'Please remember to save your company.']);
        Toast.success(this, [message]);
        this.$bvModal.hide("bv-modal-company-membership");
      });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-company-membership");
    },
    ...mapActions(["saveCompanyMembership"])
  }
};
</script>
