<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">{{actionType}} Project Sample</h4>
      </div>

      <div class="modal-body">
        <div class="form-row">
          <div class="col mb-3">
            <label for="title">Title <span class="red-dot ml-3"></span></label>
            <input id="title" name="title" type="text" class="form-control" :class="{ 'is-invalid': errors.has('title') }"
                   autocomplete="off" placeholder="Title" v-validate="'required'" v-model="projectSample.title" data-vv-as="Title" />
            <div class="invalid-feedback">The Title field is required</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col mb-3">
            <label for="description">Description <span class="red-dot ml-3"></span></label>
            <input id="description" name="description" type="text" class="form-control" :class="{ 'is-invalid': errors.has('description') }"
                   autocomplete="off" placeholder="Description" v-validate="'required'" v-model="projectSample.description" data-vv-as="Description" />
            <div class="invalid-feedback">The Description field is required</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col mb-3">
            <label for="cost">Cost <span class="red-dot ml-3"></span></label>
            <input id="cost" type="number" min="0.0" step=".01" name="cost" class="form-control" :class="{ 'is-invalid': errors.has('cost') }"
                   autocomplete="off" placeholder="Cost" v-validate="'required'" v-model="projectSample.cost" data-vv-as="Cost" />
            <div class="invalid-feedback">The Cost field is required</div>
          </div>
        </div>

        <div class="form-row">
          <div class="col">
            <label for="image">Image</label>
            <UploadFile id="image" type="image" displayName="Image" :acceptTypes="['jpg', 'jpeg', 'png']"
              :existingFile.sync="projectSample.image" @saveFile="saveImage"></UploadFile>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input id="awarded" type="checkbox"
                   class="custom-control-input"
                   v-model="projectSample.awarded">
            <label class="custom-control-label" for="awarded">Awarded</label>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import { CompanyService } from "@/services/";
  import cloneDeep from "lodash/cloneDeep";
  import Toast from "@/utils/toast";
  import UploadFile from "@/components/upload-file.vue";

  export default {
    name: "company-user",
    components: {
      UploadFile
    },
    data: function () {
      return {
        isEdit: false,
        projectSample: {
          id: null,
          title: null,
          description: null,
          image: null,
          uploadedImage: null,
          awarded: false,
          cost: null
        }
      };
    },
    computed: {
      ...mapGetters(["getCompanyProjectSample", "getCompany"]),
      actionType: function () {
        return this.isEdit ? 'Edit' : 'Add';
      }
    },
    mounted: function () {
      this.projectSample = cloneDeep(this.getCompanyProjectSample);
      this.isEdit = !!this.projectSample.id;
      this.$validator.reset();
    },
    methods: {
      async onSubmit() {
        var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          return;
        }

        this.saveCompanyProjectSample(this.projectSample).then(() => {
          let message = this.$createElement('span', {}, ['Successfully updated!', this.$createElement('br'), 'Please remember to save your company.']);
          Toast.success(this, [message]);
          this.$bvModal.hide("bv-modal-company-project-sample");
        }).catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
      },
      closeModal() {
        this.$bvModal.hide("bv-modal-company-project-sample");
      },
      saveImage(uploadedFile) {
        CompanyService.saveCompanyFile(uploadedFile, this.getCompany.id, 'project-sample').then(response => this.projectSample.image = response.data);
      },
      ...mapActions(["saveCompanyProjectSample"])
    }
  };
</script>
