<template>
  <fragment>
    <div id="membership-list">
      <h3>Membership Levels</h3>
      <div class="alert alert-warning" role="alert" v-if="getCompanyMemberships.length === 0">
        No memberships available
      </div>
      <table class="table" v-if="getCompanyMemberships.length > 0">
        <thead>
          <tr>
            <th scope="col">Zone</th>
            <th scope="col">Membership Level</th>
            <th scope="col">Expiry Date</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="membership in getCompanyMemberships" :key="membership.id">
            <td>{{ membership.zone }}</td>
            <td>{{ membership.role }}</td>
            <td>{{ membership.expiryDate | datetime }}</td>
            <td>
              <a v-if="hasCompanyMembershipEditPermission" role="button" href="#" class="d-flex justify-content-between align-items-center flex-column"
                  title="Manage" @click.prevent="editMembership(membership.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Manage</span>
              </a>
            </td>
            <td>
              <a v-if="hasCompanyMembershipEditPermission" role="button" href="#" class="d-flex justify-content-between align-items-center flex-column"
                  title="Manage" @click.prevent="deleteMembership(membership)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Remove</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mb-3" v-if="hasCompanyMembershipEditPermission">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newMembership">
            <i class="fas fa-plus"></i>
            Add New Membership
          </button>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-company-membership" size="lg" hide-header hide-footer :no-close-on-backdrop="true">
      <company-membership />
    </b-modal>
  </fragment>
</template>

<script>
import CompanyMembership from "@/views/company/components/company-membership.vue";
import { mapGetters, mapActions } from "vuex";
import MessageBox from "@/utils/messagebox";
import Toast from "@/utils/toast";

export default {
  name: "company-memberships",
  components: { CompanyMembership },
  computed: {
    hasCompanyMembershipEditPermission () {
      return this.$permissions().isSysAdmin || this.$permissions().isClientAdmin;
    },
    ...mapGetters(["getCompanyMemberships", "getRoles", "getZones"])
  },
  methods: {
    newMembership() {
      this.resetCompanyMembership().then(() => {
        this.$bvModal.show("bv-modal-company-membership");
      });
    },
    editMembership(id) {
      this.fetchCompanyMembership(id).then(() => {
        this.$bvModal.show("bv-modal-company-membership");
      });
    },
    async deleteMembership(membership) {
      let confirm = await MessageBox.confirm(
        this,
        "Remove Membership",
        `Are you sure you want to remove this membership?`
      );

      if (confirm) {
        this.removeCompanyMembership(membership.id).then(() => {
          Toast.success(this, "Successfully removed user!");
        });
      }
    },
    ...mapActions([
      "fetchCompanyMembership",
      "resetCompanyMembership",
      "removeCompanyMembership"
    ])
  }
};
</script>
