<template>
  <fragment>
    <div class="form-group">
      <label for="companyName">Company Name <span class="red-dot ml-3"></span></label>
      <input id="companyName" name="companyName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('companyName') }"
             autocomplete="off" placeholder="Company Name" v-validate="'required'" v-model="company.name" data-vv-as="Company Name"
             :readonly="!hasAdminPermission" :disabled="!hasAdminPermission"
             @change="companyNameChanged" />
      <div class="invalid-feedback">The Company Name field is required</div>
    </div>

    <div class="form-group">
      <label for="companyEmail">Company Email</label>
      <input id="companyEmail" name="companyEmail" type="text" class="form-control" :class="{ 'is-invalid': errors.has('companyEmail') }"
             autocomplete="off" placeholder="Email" v-model="company.email" v-validate="'email'" data-vv-as="Company Email"
             :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)" />
      <div class="invalid-feedback">{{ errors.first('companyEmail') }}</div>
    </div>

    <div class="form-group">
      <label for="website">Website</label>
      <input id="website" name="website" type="text" class="form-control" :class="{ 'is-invalid': errors.has('website') }"
             autocomplete="off" placeholder="Website" v-model="company.website" v-validate="'url'" data-vv-as="Website"
             :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)" />
      <div class="invalid-feedback">{{ errors.first('website') }}</div>
    </div>

    <div class="form-group">
      <label for="address">Address <span class="red-dot ml-3"></span></label>
      <input id="address" name="address" type="text" class="form-control" :class="{ 'is-invalid': errors.has('address') }"
             autocomplete="off" placeholder="Address" v-validate="'required'" v-model="company.address.street" data-vv-as="Address"
             :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)" />
      <div class="invalid-feedback">The Address field is required</div>
    </div>

    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="city">City <span class="red-dot ml-3"></span></label>
        <input id="city" name="city" type="text" class="form-control" :class="{ 'is-invalid': errors.has('city') }"
               autocomplete="off" placeholder="City" v-validate="'required'" v-model="company.address.city" data-vv-as="City"
               :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)" />
        <div class="invalid-feedback">The City field is required</div>
      </div>
    </div>
    <div class="form-row">
      <div class="col-md-4 mb-3">
        <label for="country">Country <span class="red-dot ml-3"></span></label>
        <select id="country" name="country" class="custom-select" :class="{ 'is-invalid': errors.has('country') }"
                v-validate="'required'" v-model="countryId" data-vv-as="Country" v-on:change="filterProvinces"
                :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)">
          <option v-for="country in getCountries" :key="country.id" :value="country.id">{{ country.name }}</option>
        </select>
        <div class="invalid-feedback">The Country field is required</div>
      </div>
      <div class="col-md-4 mb-3">
        <label for="province">Province/State <span class="red-dot ml-3"></span></label>
        <select id="province" name="province" class="custom-select" :class="{ 'is-invalid': errors.has('province') }"
                v-validate="'required'" v-model="company.address.provinceId" data-vv-as="Province"
                :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)">
          <option v-for="province in provinceList" :key="province.id" :value="province.id">{{ province.name }}</option>
        </select>
        <div class="invalid-feedback">The Province/State field is required</div>
      </div>
      <div class="col-md-4 mb-3">
        <label for="postalCode">Postal Code/Zip Code</label>
        <input id="postalCode" name="postalCode" type="text" class="form-control" autocomplete="off" placeholder="Postal Code" v-model="company.address.postalCode"
               :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)" />
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-6 mb-3">
        <label for="phone">Phone</label>
        <input id="phone" name="phone" type="text" class="form-control" autocomplete="off" placeholder="Phone" v-model="company.phone"
               :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)" />
      </div>
      <div class="col-md-6 mb-3">
        <label for="fax">Fax</label>
        <input id="fax" name="fax" type="text" class="form-control" autocomplete="off" placeholder="Fax" v-model="company.fax"
               :readonly="!(hasAdminPermission || hasCompanyInformationEditPermission)" :disabled="!(hasAdminPermission || hasCompanyInformationEditPermission)" />
      </div>
    </div>
  </fragment>
</template>

<script>
  import { mapGetters } from "vuex";
 
  export default {
    name: "company-information",
    inject: ["$validator"],
    props: ["company"],
    data: function(){
        return {
          countryId: null,
          provinceList: null
        };
    },
    mounted: function() {
        this.setCountry();
        this.filterProvinces();
    },
    computed: {
      hasAdminPermission() {
        return this.$permissions().isSysAdmin || this.$permissions().isClientAdmin;
      },
      hasCompanyInformationEditPermission() {
        return this.$permissions().hasEditCompanyPermission;
      },
      ...mapGetters(["getProvinces","getCountries"])
    },
    methods: {
      companyNameChanged() {
        this.$emit('updateAlias');
      },
      setCountry() {
        let selectedProvince = this.getProvinces.filter((p) =>{ return p.id==this.company.address.provinceId});
        if(selectedProvince.length >0){
          this.countryId = selectedProvince[0].countryId;
        }
        
      },
      filterProvinces() {
        this.provinceList = this.getProvinces.filter((p)=>{return p.countryId == this.countryId});
      }
    }
  };
</script>
