<template>
  <fragment>
    <div id="associations-list">
      <h3>Associations</h3>

      <div class="alert alert-warning"
           role="alert"
           v-if="getCompanyAssociations.length === 0">
        No company associations available
      </div>

      <table class="table" v-if="getCompanyAssociations.length > 0">
        <thead>
          <tr>
            <th scope="col">Association Text</th>
            <th scope="col">Expiry Date</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="companyAssociation in getCompanyAssociations" :key="companyAssociation.id">
            <td>{{ companyAssociation.name }}</td>
            <td>{{ companyAssociation.expirationDate | datetime }}</td>
            <td>
              <a role="button"
                 href="#"
                 class="d-flex justify-content-between align-items-center flex-column"
                 title="Manage"
                 @click.prevent="editAssociation(companyAssociation.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Manage</span>
              </a>
            </td>
            <td>
              <a role="button"
                 href="#"
                 class="d-flex justify-content-between align-items-center flex-column"
                 title="Delete"
                 @click.prevent="deleteAssociation(companyAssociation.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Remove</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row mb-3">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newAssociation">
            <i class="fas fa-plus"></i>
            Add New Association
          </button>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-company-association"
             size="lg"
             hide-header
             hide-footer
             :no-close-on-backdrop="true">
      <company-association />
    </b-modal>
  </fragment>
</template>

<script>
  import CompanyAssociation from "@/views/company/components/company-association.vue";
  import { mapGetters, mapActions } from "vuex";
  import MessageBox from "@/utils/messagebox";
  import Toast from "@/utils/toast";

  export default {
    name: "company-associations",
    components: { CompanyAssociation },
    computed: {
      ...mapGetters(["getCompanyAssociations"])
    },
    methods: {
      newAssociation() {
        this.resetCompanyAssociation().then(() => {
          this.$bvModal.show("bv-modal-company-association");
        });
      },
      editAssociation(id) {
        this.fetchCompanyAssociation(id).then(() => {
          this.$bvModal.show("bv-modal-company-association");
        });
      },
      async deleteAssociation(id) {
        let confirm = await MessageBox.confirm(
          this,
          "Remove Association",
          "Are you sure you want to remove this association?"
        );

        if (confirm) {
          this.removeCompanyAssociation(id).then(() => {
            Toast.success(this, "Successfully removed association!");
          });
        }
      },
      ...mapActions([
        "fetchCompanyAssociation",
        "saveCompanyAssociation",
        "resetCompanyAssociation",
        "removeCompanyAssociation"
      ])
    }
  };
</script>
