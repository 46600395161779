<template>
  <fragment>
    <div id="membership-list">
      <h3>Users</h3>
      <div
        class="alert alert-warning"
        role="alert"
        v-if="getCompanyUsers.length === 0"
      >No users available</div>

      <table class="table" v-if="getCompanyUsers.length > 0">
        <thead>
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Title</th>
            <th scope="col">Show in Invite to Bid</th>
            <th scope="col">Show in Public Directory</th>
            <th scope="col">Last Login Date</th>
            <th scope="col">Roles</th>
            <th scope="col">Approval Status</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="companyUser in getCompanyUsers" :key="companyUser.id" :class="{'table-secondary': companyUser.acceptanceStatus === publicCompanyJoinAcceptanceStatus.Pending }">
            <td>{{ getUserName(companyUser.user) }}</td>
            <td>{{ companyUser.user.email }}</td>
            <td>{{ companyUser.title }}</td>
            <td>
              <input type="checkbox" class="form-check-input ml-4" :checked="companyUser.acceptITBInvite" :value="companyUser.id" @change="updateUserAcceptITB($event)" />
            </td>
            <td>
              <input type="checkbox" class="form-check-input ml-4" :checked="companyUser.showContact" :value="companyUser.id" @change="updateUserContact($event)" />
            </td>
            <td>{{ companyUser.user.lastLoggedIn | datetime('MMM dd yyyy') }}</td>
            <td>{{ getUserRoles(companyUser.user.roleIds) }}</td>
            <td>{{ companyUser.acceptanceStatus | companyJoinAcceptanceStatusFilter}} 
              <span v-if="companyUser.acceptanceStatus === publicCompanyJoinAcceptanceStatus.Pending"><br/>                
                <label class="radio-inline mr-4" for="approvedRadio">
                  <input type="radio" :name="`acceptanceStatus${companyUser.id}`" :id="`approvedRadio${companyUser.id}`" class="form-radio-input mr-1" :value="publicCompanyJoinAcceptanceStatus.Approved"  @change="acceptanceStatusChange(companyUser.id, publicCompanyJoinAcceptanceStatus.Approved)" >
                  <i>Approve</i></label>
                
                <label class="radio-inline mr-4" for="rejectedRadio">
                  <input type="radio" :name="`acceptanceStatus${companyUser.id}`" :id="`rejectedRadio${companyUser.id}`" class="form-radio-input mr-1" :value="publicCompanyJoinAcceptanceStatus.Rejected"  @change="acceptanceStatusChange(companyUser.id, publicCompanyJoinAcceptanceStatus.Rejected)" >
                  <i>Reject</i></label>
                
                <label  class="radio-inline" for="pendingRadio" >
                  <input type="radio" :name="`acceptanceStatus${companyUser.id}`" :id="`pendingRadio${companyUser.id}`" class="form-radio-input mr-1" :value="publicCompanyJoinAcceptanceStatus.Pending" checked @change="acceptanceStatusChange(companyUser.id, publicCompanyJoinAcceptanceStatus.Pending)" >
                  <i>Pending</i></label>
              </span>
            </td>
            <td>
              <a
                role="button"
                href="#"
                class="d-flex justify-content-between align-items-center flex-column"
                title="Manage"
                @click.prevent="editUser(companyUser.id)"
              >
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Manage</span>
              </a>
            </td>
            <td>
              <a
                role="button"
                href="#"
                class="d-flex justify-content-between align-items-center flex-column"
                title="Delete"
                @click.prevent="deleteUser(companyUser.id)"
              >
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Remove</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mb-3">
        <div class="col-md-12 text-right">
          <button v-if="pendingUsers && pendingUsers.length > 0" type="button" class="btn btn-primary mr-2" @click="approveReject()" :disabled="approveRejectBucketEmpty">           
            Approve/Reject
          </button>
          <button v-if="pendingUsers && pendingUsers.length > 0" type="button" class="btn btn-primary mr-2" @click="approveAll()" :disabled="!canApproveAll">
            <i class="fas fa-user-check"></i>
            Approve All New Users
          </button>
          <button type="button" class="btn btn-primary" @click="newUser">
            <i class="fas fa-plus"></i>
            Add New User
          </button>
        </div>
      </div>
    </div>
    <b-modal
      id="bv-modal-company-user"
      size="lg"
      hide-header
      hide-footer
      :no-close-on-backdrop="true"
    >
      <company-user />
    </b-modal>
  </fragment>
</template>

<script>
import CompanyUser from "@/views/company/components/company-user.vue";
import { mapGetters, mapActions } from "vuex";
import MessageBox from "@/utils/messagebox";
import Toast from "@/utils/toast";
import { PublicCompanyJoinAcceptanceStatus } from "@/utils/enums";

export default {
  name: "company-user-information",
  data: function () {
    return {
      publicCompanyJoinAcceptanceStatus: PublicCompanyJoinAcceptanceStatus,
      approveRejectBucket: []
    }
  },
  components: { CompanyUser },
  computed: {
    ...mapGetters(["getCompanyMemberships", "getCompanyUsers", "getRoles"]),
    pendingUsers(){
      return  this.getCompanyUsers.filter( companyUser => companyUser.acceptanceStatus === this.publicCompanyJoinAcceptanceStatus.Pending);
    },
    approveRejectBucketEmpty(){
      return  !this.approveRejectBucket || this.approveRejectBucket.length < 1;
    },
    canApproveAll(){
      return  this.approveRejectBucketEmpty || this.approveRejectBucket.findIndex(i => i.status === this.publicCompanyJoinAcceptanceStatus.Rejected) === -1;
    }
  },
  methods: {
    getRoleName(id) {
      let role = this.getRoles.find(role => role.id === id);
      return (role && role.name) || "";
    },
    getUserName(user) {
      return (user.firstName || "") + " " + (user.lastName || "");
    },
    getUserRoles(roleIds = []) {
      let companyRoleIds = this.getCompanyMemberships.map(m => m.roleId);
      let combinedRoleIds = [...roleIds, ...companyRoleIds];

      if (!combinedRoleIds.length) return "No Roles Defined";
      return combinedRoleIds.map(roleId => this.getRoleName(roleId)).join(", ");
    },
    newUser() {
      this.resetCompanyUser().then(() => {
        this.$bvModal.show("bv-modal-company-user");
      });
    },
    editUser(id) {
      this.fetchCompanyUser(id).then(() => {
        this.$bvModal.show("bv-modal-company-user");
      });
    },
    showContactUser(id) {
      this.fetchCompanyUser(id).then(data => {
        return data.ShowContact;
      });
    },
    async deleteUser(id) {
      let confirm = await MessageBox.confirm(
        this,
        "Remove User",
        "Are you sure you want to remove user?"
      );

      if (confirm) {
        this.removeCompanyUser(id).then(() => {
          Toast.success(this, "Successfully removed user!");
        });
      }
    },
    updateUserAcceptITB(e) {
      let id = e.target.value;
      let shown = e.target.checked;
      this.updateCompanyUserAcceptITB({ id, shown });
    },
    updateUserContact(e) {
      let id = e.target.value;
      let shown = e.target.checked;
      this.updateCompanyUserContact({ id, shown });
    },
    updateUserAcceptJoin(id, status) {
      this.updateUserCompanyJoinStatus({ id, status });
    },
    approveAll() {
      this.pendingUsers.forEach((companyUser) => {
        this.updateUserAcceptJoin(companyUser.id, this.publicCompanyJoinAcceptanceStatus.Approved); 
      });  
      this.approveRejectBucket = [];    
    },
    approveReject() {
      this.approveRejectBucket.forEach((companyUser) => {
        this.updateUserAcceptJoin(companyUser.id, companyUser.status); 
      });  
      this.approveRejectBucket = [];   
    },
    acceptanceStatusChange(id, status) {
      let index = this.approveRejectBucket.findIndex(i => i.id === id);
      if(index > -1){
        this.approveRejectBucket.splice(index, 1);
      }
      if(status != this.publicCompanyJoinAcceptanceStatus.Pending){
        this.approveRejectBucket.push({id, status});
      }      
    },
    ...mapActions([
      "fetchCompanyUser",
      "resetCompanyUser",
      "removeCompanyUser",
      "updateCompanyUserAcceptITB",
      "updateCompanyUserContact",
      "updateUserCompanyJoinStatus"
    ])
  }
};
</script>
