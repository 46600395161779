<template>
  <fragment>
    <div id="membership-list">
      <h3>Social Media</h3>
      <div class="alert alert-warning"
           role="alert"
           v-if="getCompanySocialMediaList.length === 0">
        No social media available
      </div>

      <table class="table" v-if="getCompanySocialMediaList.length > 0">
        <thead>
          <tr>
            <th scope="col">Social Media Type</th>
            <th scope="col">URL</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="companySocialMedia in getCompanySocialMediaList" :key="companySocialMedia.id">
            <td width="300">{{ formatName(companySocialMedia.socialMediaType.name) }}</td>
            <td width="300"><a target="_blank" :href="companySocialMedia.companySocialMediaTypeUrl">{{ companySocialMedia.companySocialMediaTypeUrl }}</a></td>
            <td>
              <a role="button"
                 href="#"
                 class="d-flex justify-content-between align-items-center flex-column"
                 title="Manage"
                 @click.prevent="editSocialMedia(companySocialMedia.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Manage</span>
              </a>
            </td>
            <td>
              <a role="button"
                 href="#"
                 class="d-flex justify-content-between align-items-center flex-column"
                 title="Delete"
                 @click.prevent="deleteSocialMedia(companySocialMedia.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Remove</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mb-3">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newSocialMedia">
            <i class="fas fa-plus"></i>
            Add New Social Media
          </button>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-company-social-media"
             size="lg"
             hide-header
             hide-footer
             :no-close-on-backdrop="true">
      <company-social-media />
    </b-modal>
  </fragment>
</template>

<script>
  import CompanySocialMedia from "@/views/company/components/company-social-media.vue";
  import { mapGetters, mapActions } from "vuex";
  import MessageBox from "@/utils/messagebox";
  import Toast from "@/utils/toast";
 
  export default {
    name: "company-social-media-information",
    components: { CompanySocialMedia },
    computed: {
      ...mapGetters(["getCompanySocialMediaList"])
    },
    methods: {
      newSocialMedia() {
        this.resetCompanySocialMedia().then(() => {
          this.$bvModal.show("bv-modal-company-social-media");
        });
      },
      editSocialMedia(id) {
        this.fetchCompanySocialMedia(id).then(() => {
          this.$bvModal.show("bv-modal-company-social-media");
        });
      },
     async deleteSocialMedia(id) {
        let confirm = await MessageBox.confirm(
          this,
          "Remove Social Media",
          "Are you sure you want to remove this social media?"
        );

        if (confirm) {
          this.removeCompanySocialMedia(id).then(() => {
            Toast.success(this, "Successfully removed social media!");
          });
        }
      },
      formatName(socialMediaName) {
        return socialMediaName[0].toUpperCase() + socialMediaName.substring(1, socialMediaName.length);
      },
     ...mapActions([
        "fetchCompanySocialMedia",
        "resetCompanySocialMedia",
        "removeCompanySocialMedia"
      ])
    }
  };
</script>
