<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">
          {{ isEdit ? 'Edit' : 'Add' }} User
        </h4>
      </div>

      <div class="modal-body">
        <div class="form-row">
          <div class="col-md-6 mb-3">
            <label for="firstName">First Name <span class="red-dot ml-3"></span></label>
            <input id="firstName" name="firstName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('firstName') }"
              autocomplete="off" placeholder="First name" v-validate="'required'" v-model="companyUser.user.firstName" data-vv-as="First Name" />
            <div class="invalid-feedback">The First Name field is required</div>
          </div>

          <div class="col-md-6 mb-3">
            <label for="lastName">Last Name <span class="red-dot ml-3"></span></label>
            <input id="lastName" name="lastName" type="text" class="form-control" :class="{ 'is-invalid': errors.has('lastName') }"
              autocomplete="off" placeholder="Last name" v-validate="'required'" v-model="companyUser.user.lastName" data-vv-as="Last Name" />
            <div class="invalid-feedback">The Last Name field is required</div>
          </div>
        </div>

        <div class="form-group row" v-if="isEdit === true">
          <label for="userEmail" class="col-sm-2 col-form-label">Email</label>
          <div class="col-sm-10">
            <input id="userEmail" name="userEmail" type="text"  class="form-control-plaintext" :value="companyUser.user.email" readonly disabled />
          </div>
        </div>

        <div class="form-group" v-if="isEdit === false">
          <label for="email">Email <span class="red-dot ml-3"></span></label>
          <input id="email" name="email" type="text" class="form-control" :class="{ 'is-invalid': errors.has('email') }"
            autocomplete="off" placeholder="Email" v-validate="'required|email'" v-model="companyUser.user.email" data-vv-as="Email" />
          <div class="invalid-feedback">{{ errors.first('email') }}</div>
        </div>

        <div class="form-group">
          <label for="title">Title</label>
          <input id="title" name="title" type="text" class="form-control" autocomplete="off" placeholder="Title" v-model="companyUser.title">
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input id="showContact" name="showContact" type="checkbox" data-toggle="toggle" class="custom-control-input" v-model="companyUser.showContact"/>
            <label class="custom-control-label" for="showContact">
              Show contact in public directory
            </label>
            <small class="form-text text-muted">
              Only first 5 contacts will be displayed in public directory
            </small>
          </div>
        </div>

        <div class="form-group">
          <div class="custom-control custom-checkbox">
            <input id="acceptITBInvite" name="acceptITBInvite" type="checkbox" data-toggle="toggle" class="custom-control-input" v-model="companyUser.acceptITBInvite"/>
            <label class="custom-control-label" for="acceptITBInvite">
              Accept ITB Invitation
            </label>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import cloneDeep from "lodash/cloneDeep";
import Toast from "@/utils/toast";
import { PublicCompanyJoinAcceptanceStatus } from "@/utils/enums";

export default {
  name: "company-user",
  data: function() {
    return {
      isEdit: false,
      companyUser: {
        id: null,
        user: {
          firstName: null,
          lastName: null,
          email: null,
        },
        title: null,
        showContact: false,
        acceptITBInvite: false
      }
    };
  },
  computed: {
    ...mapGetters(["getCompanyUser"])
  },
  mounted: function() {
    this.companyUser = cloneDeep(this.getCompanyUser);
    this.isEdit = !!this.companyUser.id;
    this.$validator.reset();
  },
  methods: {
    async onSubmit() {
      var isFormValid = await this.$validator.validate();

      if (!isFormValid) {
        return;
      }
      if(this.isEdit=== false){
        this.companyUser.acceptanceStatus = PublicCompanyJoinAcceptanceStatus.Approved;
      }

      this.saveCompanyUser(this.companyUser).then(() => {
        let message = this.$createElement('span', {}, [ 'Successfully updated!', this.$createElement('br'), 'Please remember to save your company.']);
        Toast.success(this, [message]);
        this.$bvModal.hide("bv-modal-company-user");
      });
    },
    closeModal() {
      this.$bvModal.hide("bv-modal-company-user");
    },
    ...mapActions(["saveCompanyUser"])
  }
};
</script>
