<template>
  <fragment>
    <div>
      <h3>Project Samples</h3>
      <div class="alert alert-warning"
           role="alert"
           v-if="getCompanyProjectSamples.length === 0">
        No project samples available
      </div>

      <table class="table" v-if="getCompanyProjectSamples.length > 0">
        <thead>
          <tr>
            <th scope="col">Project Title</th>
            <th scope="col">Project Description</th>
            <th scope="col">Cost</th>
            <th scope="col">Awarded</th>
            <th scope="col">Image</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
            <th scope="col" style="width: 100px">&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="companyProjectSample in getCompanyProjectSamples" :key="companyProjectSample.id">
            <td>{{ companyProjectSample.title }}</td>
            <td>{{ companyProjectSample.description }}</td>
            <td>{{ companyProjectSample.cost | currency(2) }}</td>
            <td>{{ companyProjectSample.awarded ? 'Yes' : 'No'  }}</td>
            <td>{{ getImage(companyProjectSample)  }}</td>
            <td>
              <a role="button"
                 href="#"
                 class="d-flex justify-content-between align-items-center flex-column"
                 title="Manage"
                 @click.prevent="editProjectSample(companyProjectSample.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Manage</span>
              </a>
            </td>
            <td>
              <a role="button"
                 href="#"
                 class="d-flex justify-content-between align-items-center flex-column"
                 title="Delete"
                 @click.prevent="deleteProjectSample(companyProjectSample.id)">
                <img class="mb-1" src="@/assets/document.svg" alt />
                <span class="small-grey">Remove</span>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="row mb-3">
        <div class="col-md-12 text-right">
          <button type="button" class="btn btn-primary" @click="newProjectSample">
            <i class="fas fa-plus"></i>
            Add New Project Sample
          </button>
        </div>
      </div>
    </div>
    <b-modal id="bv-modal-company-project-sample"
             size="lg"
             hide-header
             hide-footer
             :no-close-on-backdrop="true">
      <company-project-sample  />
    </b-modal>
  </fragment>
</template>

<script>
  import CompanyProjectSample from "@/views/company/components/company-project-sample.vue";
  import { mapGetters, mapActions } from "vuex";
  import MessageBox from "@/utils/messagebox";
  import Toast from "@/utils/toast";

  export default {
    name: "company-project-samples",
    components: { CompanyProjectSample },
    computed: {
      ...mapGetters(["getCompanyProjectSamples"])
    },
    methods: {
      newProjectSample() {
        this.resetCompanyProjectSample().then(() => {
          this.$bvModal.show("bv-modal-company-project-sample");
        });
      },
      editProjectSample(id) {
        this.fetchCompanyProjectSample(id).then(() => {
          this.$bvModal.show("bv-modal-company-project-sample");
        });
      },
      getImage(companyProjectSample) {
        if (companyProjectSample.image) {
          return companyProjectSample.image.displayName;
        }
        else {
          return 'N/A';
        }
      },
      async deleteProjectSample(id) {
        let confirm = await MessageBox.confirm(
          this,
          "Remove Project Sample",
          "Are you sure you want to remove this project sample?"
        );

        if (confirm) {
          this.removeCompanyProjectSample(id).then(() => {
            Toast.success(this, "Successfully removed project sample!");
          });
        }
      },
      ...mapActions([
        "fetchCompanyProjectSample",
        "resetCompanyProjectSample",
        "removeCompanyProjectSample"
      ])
    }
  };
</script>
