<template>
  <fragment>
    <form v-on:submit.prevent="onSubmit">
      <div class="modal-header">
        <h4 class="modal-title">{{actionType}} Association</h4>
      </div>

      <div class="modal-body">
        <div class="form-row">
          <div class="col mb-3">
            <label for="name">Name <span class="red-dot ml-3"></span></label>
            <input id="name" name="name" type="text" class="form-control" :class="{ 'is-invalid': errors.has('name') }"
                   autocomplete="off" placeholder="Name" v-validate="'required'" v-model="association.name" data-vv-as="Name" />
            <div class="invalid-feedback">The Name field is required</div>
          </div>
        </div>
        <div class="form-row">
          <div class="col mb-3">
            <label for="expirationDate">Expiry Date</label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <span class="input-group-text bg-transparent pr-2">
                  <img src="@/assets/cal.svg" alt />
                </span>
              </div>
              <date-picker id="expirationDate" name="expirationDate" class="form-control date-picker-input"
                           autocomplete="off" v-model="association.expirationDate" :config="datepickerOptions">
              </date-picker>

            </div>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <button type="submit" class="btn btn-success">Save</button>
        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
      </div>
    </form>
  </fragment>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import DatePicker from "vue-bootstrap-datetimepicker";
import cloneDeep from "lodash/cloneDeep";
  import Toast from "@/utils/toast";

  export default {
    name: "company-user",
    components: { DatePicker },
    data: function () {
      return {
        isEdit: false,
        association: {
          id: null,
          name: null,
          expirationDate: null
        },
        datepickerOptions: null
      };
    },
    computed: {
      ...mapGetters(["getCompanyAssociation"]),
      actionType: function () {
        return this.isEdit ? 'Edit' : 'Add';
      }
    },
    created: function () {
      this.datepickerOptions = {
        useCurrent: false,
        format: "L"
      };
    },
    mounted: function () {
      this.association = cloneDeep(this.getCompanyAssociation);

      if (this.association.expirationDate) {
        this.association.expirationDate = new Date(this.association.expirationDate);
      }

      this.isEdit = !!this.association.id;
      this.$validator.reset();
    },
    methods: {
      async onSubmit() {
        var isFormValid = await this.$validator.validate();

        if (!isFormValid) {
          return;
        }

        if (this.association.expirationDate != null) {
          this.association.expirationDate = new Date(this.association.expirationDate).toISOString();
        }

        this.saveCompanyAssociation(this.association).then(() => {
          let message = this.$createElement('span', {}, ['Successfully updated!', this.$createElement('br'), 'Please remember to save your company.']);
          Toast.success(this, [message]);
          this.$bvModal.hide("bv-modal-company-association");
        }).catch(() => {
          Toast.danger(this, "Oops! There was an error.");
          this.closeModal();
        });
      },
      closeModal() {
        this.$bvModal.hide("bv-modal-company-association");
      },
      ...mapActions(["saveCompanyAssociation"])
    }
  };
</script>
